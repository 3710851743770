<template>
  <div id="storico-ordini">
    <div>
      <div>
        <div>
          <div class="filtro">
            <!-- <el-input
              v-model="findModel"
              placeholder="MODELLO"
              suffix-icon="el-icon-search"
            ></el-input>-->
            <el-input
              v-model="findRef"
              :placeholder="$t('message.Riferimento').toUpperCase()"
              suffix-icon="el-icon-search"
            ></el-input>
            <el-input
              v-model="findFrontColor"
              :placeholder="$t('message.colore_frontale').toUpperCase()"
              suffix-icon="el-icon-search"
            ></el-input>
            <el-date-picker
              v-model="findDate"
              type="date"
              :placeholder="$t('message.Data')"
              format="yyyy/MM/dd"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
            <!-- <el-input
              v-model="findDate"
              placeholder="DATA"
              suffix-icon="el-icon-search"
            ></el-input>-->
          </div>
        </div>
        <div id="tabella-storico">
          <div v-if="historyLoading" class="progress">
            <el-progress
              type="line"
              :text-inside="true"
              :stroke-width="26"
              :percentage="percentage"
              color="@--color-primary"
            ></el-progress>
          </div>
          <el-table
            v-else
            class="history-table"
            :data="pagedHistoryRows"
            border
            stripe
            :height="heightTableBody"
            max-height="50vh"
            :row-style="{height: '60px'}"
            :empty-text="$t('message.Nessun_ordine_trovato')"
            :header-cell-style="{height: '71px'}"
          >
            <el-table-column :label="$t('message.Data').toUpperCase()" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.date | asDate }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="ID"
              :label="$t('message.Ordine_numero').toUpperCase()"
              align="center"
              sortable
            ></el-table-column>
            <!-- <el-table-column label="CALIBRO-PONTE" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.calibro }}/{{ scope.row.naso }}</span>
              </template>
            </el-table-column>-->
            <el-table-column prop="tot" :label="$t('message.Quantità').toUpperCase()" align="center"></el-table-column>
            <el-table-column
              prop="Notes"
              :label="$t('message.Note').toUpperCase()"
              align="center"
              min-width="200"
            ></el-table-column>
            <el-table-column prop="row_state" :label="$t('message.Stato')" align="center"></el-table-column>

            <el-table-column :label="$t('message.Visualizza_il_tuo_ordine')" align="center">
              <template slot-scope="scope">
                <el-button
                  @click="visOrd =true; details = scope.row.details"
                  type="text"
                  size="medium"
                  style="color: #038EAA !important"
                >{{$t('message.Visualizza').toUpperCase()}}</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="pagination">
          <el-pagination
            background
            @size-change="setSizePage"
            @current-change="setPage"
            :current-page.sync="page"
            :page-sizes="pageSizes"
            :page-size="this.pageSize"
            layout="sizes, jumper, total, prev, next"
            :total="this.orderHistory ? this.orderHistory.length : 0"
          ></el-pagination>
        </div>

        <!-- Dialog del dettaglio -->
        <el-dialog
          class="details-dialog"
          width="75%"
          :title="$t('message.Ordine_numero').toUpperCase() + ' ' + this.idDetails"
          :visible.sync="visOrd"
          :modal="false"
          :show-close="false"
        >
          <!-- Dialog per personalizzazione -->
          <el-dialog
            class="details-dialog"
            title="PERSONALIZZAZIONI"
            :visible.sync="persDialog"
            :show-close="false"
            :modal="false"
            width="50%"
          >
            <el-table
              class="history-table"
              :data="pers"
              border
              stripe
              :empty-text="$t('message.Nessuna_configurazione')"
              max-height="500"
              :row-class-name="getRowClass"
              append-to-body
            >
              <el-table-column prop="label" :label="$t('message.Posizione').toUpperCase()" align="center"></el-table-column>
              <!-- <el-table-column prop="nome" label="TESTO" align="center"></el-table-column> -->
              <el-table-column :label="$t('message.Testo').toUpperCase()" align="center">
                <template slot-scope="scope">
                  <span
                    :style="{fontFamily: scope.row.fontTesto}"
                  >{{scope.row.nome}}</span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('message.Colore') | upperCase" align="center">
                <template
                  slot-scope="scope"
                >{{scope.row.coloreTesto.toUpperCase()}}</template>
              </el-table-column>
            </el-table>
          </el-dialog>
          <el-table
            class="history-table"
            :data="details"
            border
            stripe
            :empty-text="$t('message.Nessuna_configurazione')"
            max-height="500"
          >
            <el-table-column prop="row_state" :label="$t('message.Stato') | upperCase" align="center"></el-table-column>
            <el-table-column prop="ID" label="ID" align="center"></el-table-column>
            <el-table-column prop="COD_PROD" :label="$t('message.Modello').toUpperCase()" align="center"></el-table-column>
            <el-table-column :label="$t('message.Calibro').toUpperCase()" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.size_eye }}/{{ scope.row.size_bridge }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('message.colore_frontale').toUpperCase()" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.COLORE.replaceAll('PL','') }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="Finishing"
              :label="$t('message.finitura').toUpperCase() + ' ' + $t('message.frontale').toUpperCase()"
              align="center"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.Finishing == 'S'">{{ $t("message.lucido") | upperCase }}</span>
                <span v-else-if="scope.row.Finishing == 'N'">{{$t('message.Naturale') | upperCase}}</span>
                <span v-else>{{ $t("message.opaco") }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="COD_ASTE" :label="$t('message.Aste') | upperCase" align="center"></el-table-column>
            <el-table-column
              prop="FinishingTemple"
              :label="$t('message.finitura').toUpperCase() + ' ' + $t('message.Aste').toUpperCase()"
              align="center"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.FinishingTemple == 'S'">{{ $t("message.lucido").toUpperCase() }}</span>
                <span v-else>{{ $t("message.opaco").toUpperCase() }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('message.Incisione').toUpperCase()" align="center">
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.nome != '' 
              || scope.row.EST_ASTA_SX_NOME != ''
              || scope.row.INT_ASTA_SX_NOME != ''
              || scope.row.EST_ASTA_DX_NOME != ''
              || scope.row.INT_ASTA_DX_NOME != ''"
                  @click="personaliz(scope.row.ID_RIGA)"
                  type="text"
                  size="medium"
                  style="color: #038EAA !important"
                >{{$t('message.Visualizza').toUpperCase()}}</el-button>
                <span v-else style="color: #038EAA !important">NO</span>
              </template>
            </el-table-column>
            <el-table-column
              :label="`${$t('message.Lenti').toUpperCase()}`"
              align="center"
              min-width="150"
            >
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.SUNLENS != ''"
                  @click="setDettaglioOrdineLente(scope.row)"
                  type="text"
                  size="medium"
                  style="color: #038EAA !important"
                >{{ $t("message.Visualizza") | upperCase }}</el-button>
                <span v-else style="color: #038EAA !important">NO</span>
              </template>
            </el-table-column>
            <el-table-column prop="Note" :label="$t('message.Riferimento').toUpperCase()" align="center"></el-table-column>
            <el-table-column
              prop="ID_RIGA"
              :label="$t('message.Assistenza') + '-' + $t('message.Codice')"
              align="center"
            ></el-table-column>
          </el-table>
        </el-dialog>

        <!-- Dialog dettaglio lenti -->
        <el-dialog
          class="details-dialog"
          title="LENTI"
          :visible.sync="detailsLensDialog"
          :show-close="false"
          :modal="false"
          width="50%"
        >
          <el-table
            class="history-table"
            :data="dettaglioOrdineLente"
            border
            stripe
            :row-style="{ height: '60px' }"
          >
            <el-table-column
              prop="label"
              label="LENTI"
              align="center"
              min-width="150"
            ></el-table-column>
            <el-table-column
              label="TIPOLOGIA DI LENTE"
              align="center"
              min-width="150"
            >
              <template slot-scope="scope">
                <span>
                  {{ scope.row.lensModel.replace("LENTE", "")
                  .replace("SOLE", "")
                  .replace("SOLID", "").replace("NON POLARIZZATA", "").replace("GRADIENT", "SFUMATA").replace("GRAD", "SFUMATA") }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
  import jslinq from "jslinq";
  import _ from "lodash";
  // eslint-disable-next-line no-unused-vars
  import { mapActions, mapState } from "vuex";
  export default {
    data() {
      return {
        findModel: "",
        findRef: "",
        findFrontColor: "",
        findDate: "",
        page: 1,
        pageSize: 5,
        pageSizes: [5, 10, 20, 50, 100],
        height: 0,
        // storicoOrdini: [],
        // storicoDettagli: [],
        visOrd: false,
        details: [],
        data: "",
        idDetails: 0,
        persDialog: false,
        pers: [],
        detailsLensDialog: false,
        dettaglioOrdineLente: [],
      };
    },

    mounted: async function () {
      await this.getUserHistory();
    },
    filters: {
      asDate(d) {
        let date = d.split(" ")[0];
        return date.split("-").reverse().join("/");
      },
    },
    methods: {
      // ...mapActions("user", ["getUserHistory"]),
      getUserHistory() {
        this.$store.dispatch("configurator/user/getUserHistory");
      },
      setSizePage(val) {
        this.pageSize = val;
      },

      setPage(val) {
        this.page = val;
      },

      personaliz(id_riga) {
        if (_.isEmpty(this.pers)) {
          this.details.forEach((e) => {
            if (e.ID_RIGA == id_riga) {
              this.pers.push(
                {
                  label: this.$t("message.frontale").toUpperCase(),
                  nome: e.nome,
                  coloreTesto: this.$t("message." + this.$options.filters.capitalize(e.colore_nome.replace("-", " "))),
                  fontTesto: e.font_nome,
                },
                {
                  label: "ESTERNO ASTA SINISTRA",
                  nome: e.EST_ASTA_SX_NOME,
                  coloreTesto: e.EST_ASTA_SX_COLORE_NOME,
                  fontTesto: e.EST_ASTA_SX_FONT_NOME,
                },
                {
                  label: "INTERNO ASTA SINISTRA",
                  nome: e.INT_ASTA_SX_NOME,
                  coloreTesto: e.INT_ASTA_SX_COLORE_NOME,
                  fontTesto: e.INT_ASTA_SX_FONT_NOME,
                },
                {
                  label: "ESTERNO ASTA DESTRA",
                  nome: e.EST_ASTA_DX_NOME,
                  coloreTesto: e.EST_ASTA_DX_COLORE_NOME,
                  fontTesto: e.EST_ASTA_DX_FONT_NOME,
                },
                {
                  label: "INTERNO ASTA DESTRA",
                  nome: e.INT_ASTA_DX_NOME,
                  coloreTesto: e.INT_ASTA_DX_COLORE_NOME,
                  fontTesto: e.INT_ASTA_DX_FONT_NOME,
                }
              );
            }
          });
        } else {
          this.pers = [];
          this.details.forEach((e) => {
            if (e.ID_RIGA == id_riga) {
              this.pers.push(
                {
                  label: this.$t("message.frontale").toUpperCase(),
                  nome: e.nome,
                  coloreTesto: this.$t("message." + this.$options.filters.capitalize(e.colore_nome.replace("-", " "))),
                  fontTesto: e.font_nome,
                },
                {
                  label: "ESTERNO ASTA SINISTRA",
                  nome: e.EST_ASTA_SX_NOME,
                  coloreTesto: e.EST_ASTA_SX_COLORE_NOME,
                  fontTesto: e.EST_ASTA_SX_FONT_NOME,
                },
                {
                  label: "INTERNO ASTA SINISTRA",
                  nome: e.INT_ASTA_SX_NOME,
                  coloreTesto: e.INT_ASTA_SX_COLORE_NOME,
                  fontTesto: e.INT_ASTA_SX_FONT_NOME,
                },
                {
                  label: "ESTERNO ASTA DESTRA",
                  nome: e.EST_ASTA_DX_NOME,
                  coloreTesto: e.EST_ASTA_DX_COLORE_NOME,
                  fontTesto: e.EST_ASTA_DX_FONT_NOME,
                },
                {
                  label: "INTERNO ASTA DESTRA",
                  nome: e.INT_ASTA_DX_NOME,
                  coloreTesto: e.INT_ASTA_DX_COLORE_NOME,
                  fontTesto: e.INT_ASTA_DX_FONT_NOME,
                }
              );
            }
          });
        }
        this.persDialog = true;
      },

      setDettaglioOrdineLente(row) {
        if (row.SUNLENS != "") {
          if (this.dettaglioOrdineLente.length == 0) {
            this.dettaglioOrdineLente.push({
              label: this.$t("message.Lente_sole").toUpperCase(),
              lens: row.SUNLENS,
              lensModel: row.SUNLENSDESCR,
              reference: row.Note,
              notes: "",
              lensestype: "",
              sfera: 0,
              cilindro: 0,
              asse: 0,
              semiDist: 0,
              altPup: 0,
              add: 0,
            });
          }
          this.detailsLensDialog = true;
        }
      },

      getRowClass(data) {
        return data.row.nome === "" ? "hidden" : "";
      },
    },
    computed: {
      ...mapState("configurator/user", {
        orderHistory: "orderHistory",
        historyLoading: "historyLoading",
        percentage: "percentage",
      }),
      pagedHistoryRows() {
        return this.filteredByFilter.slice(
          this.pageSize * this.page - this.pageSize,
          this.pageSize * this.page
        );
      },
      heightTableBody() {
        return this.pageSize * 60 + 71 + 1; // 60 è l'altezza delle righe della tabella impostata nel row-style, 71 è l'altezza dell'header
      },
      queryFilter() {
        return jslinq(this.orderHistory);
      },
      filteredByFilter() {
        let filtered = this.queryFilter.where((det) => {
          let hasModel = jslinq(det.details).any((c) => {
            return (
              c.COD_PROD.indexOf(this.findModel) >= 0 &&
              c.Note.indexOf(this.findRef) >= 0 &&
              c.COLORE.indexOf(this.findFrontColor) >= 0
            );
          });
          // return hasModel && det.date.indexOf(this.findDate) >= 0;
          return hasModel;
        });
        // .where((c) => {
        //   return c.date.indexOf(this.findDate) >= 0;
        // })
        // .toList();
        if (this.findDate != null) {
          filtered = filtered
            .where((c) => {
              return c.date.indexOf(this.findDate) >= 0;
            })
            .toList();
        } else {
          filtered = filtered.toList();
        }

        filtered.map((testata) => {
          testata["details"].map((dettaglio) => {
            dettaglio["COD_ASTE"] = dettaglio["COD_ASTE"].replace("CLARO", "SI:JU");
            return dettaglio;
          })
          return testata;
        })

        return filtered;
      },
    },
    watch: {
      details(newVal) {
        if (newVal) {
          this.idDetails = this.details[0].ID;
        }
      },

      // pers() {
      //   if (this.persDialog == false) this.pers = [];
      // },
    },
  };
</script>

<style lang="less">
  #storico-ordini {
    width: 100%;
    height: 100vh;

    @media (max-width: 767px) {
      .filtro {
        margin-top: 22%;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        flex-wrap: wrap;
        height: 20vh;

        .el-input {
          margin-right: 20px;
        }

        .el-date-editor {
          // margin-right: 20px;
          width: 100%;
        }
      }
    }
    @media (min-width: 768px) {
      .filtro {
        height: 20vh;
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        .el-input {
          margin-right: 20px;
          display: flex;
          align-items: center;
        }

        .el-date-editor {
          margin-right: 20px;
          width: 80%;
        }
      }
    }

    @media (max-width: 992px) {
      .filtro {
        width: 100%;
        padding: 25px 10px;
      }
    }

    @media (min-width: 992px) {
      .filtro {
        width: 80%;
        padding: 40px 15px 40px 15px;
      }
    }

    .details-dialog {
      background-color: rgba(0, 0, 0, 0.59);
      padding-top: 5%;
    }

    // #tabella-storico {
    //   height: 64vh;
    .history-table {
      background-color: @--table-header-background-color;
      .el-table__header {
        height: 71px;
      }

      .el-table__header,
      .el-table__body {
        th {
          color: @--color-primary !important;
        }
        div {
          word-break: normal !important;
        }
      }

      .el-table__empty-text {
        color: @--color-primary;
        font-weight: bold;
        font-size: 16px;
      }
      .el-table__body-wrapper {
        background-color: #fff;
      }
      // .el-table__header-wrapper {
      //   th {
      //     color: @--color-primary !important;
      //   }
      //   div {
      //     word-break: normal !important;
      //   }
      // }
    }

    .progress {
      padding: 20px;

      .el-progress-bar {
        width: 50%;
      }
      .el-progress__text {
        padding: 5px;
      }
    }
    // }

    .pagination {
      width: 100%;
      height: 5vh;
      min-height: 50px;
      .el-pagination {
        margin: 20px 0px;
        float: right;
        color: @--color-primary;
      }
    }
  }
</style>